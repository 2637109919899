<div class="main">
    <mat-drawer-container>
        <mat-drawer [mode]="drawerMode" [opened]="opened" (closedStart)="opened = false">
            <div class="sidenav-container">
                <div class="logo-box">
                    <img src="assets/images/logo.png" alt="" srcset="" />
                    <h1 class="app-name">{{ "app_name" | translate }}</h1>
                    <span class="divider"></span>
                </div>
                <div class="menu-wrapper">
                    <mat-list class="main-menu custom-main-menu">
                        @for (item of menuList; track item) {
                        <mat-list-item matRipple [routerLink]="item.route" routerLinkActive="active-route">
                            <mat-icon>{{item.icon}}</mat-icon>
                            <span>{{
                                "routes." + item.title | translate
                                }}</span>
                        </mat-list-item>
                        }

                    </mat-list>
                </div>
            </div>
        </mat-drawer>
        <mat-drawer-content>
            <mat-toolbar>
                <mat-icon (click)="toggleMenu()" color="primary" class="menu-toggle"
                    [class.flipped]="!opened">menu_open</mat-icon>
                <div class="title">{{ organzation.name }}</div>
                <div class="container-fluid align-items-end d-flex flex-row justify-content-end">
                    <!-- languages switcher -->
                    <div class="language-box d-flex flex-row align-items-center" [matMenuTriggerFor]="languageMenu"
                        title="Languages">
                        <div class="d-flex flex-column">
                            <div class="flag">
                                <ng-container *ngIf="
                                        currentLanguage === 'English';
                                        else englishTemplate
                                    ">
                                    <div class="img-container">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg/1280px-Flag_of_Great_Britain_%281707%E2%80%931800%29.svg.png"
                                            alt="" class="nav-img" />
                                    </div>
                                </ng-container>
                                <ng-template #englishTemplate>
                                    <div class="img-container">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/1200px-Flag_of_Cambodia.svg.png"
                                            alt="" class="nav-img" />
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <mat-menu #languageMenu="matMenu" class="custom-menu" xPosition="before">
                            <button mat-menu-item (click)="switchLanguage('en')" class="btn-flag">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Great_Britain_%281707%E2%80%931800%29.svg/1280px-Flag_of_Great_Britain_%281707%E2%80%931800%29.svg.png"
                                    alt="" class="img" />
                                <span>English</span>
                            </button>
                            <button mat-menu-item (click)="switchLanguage('km')" class="btn-flag">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/1200px-Flag_of_Cambodia.svg.png"
                                    alt="" class="img" />
                                <span>Khmer</span>
                            </button>
                        </mat-menu>
                    </div>

                    <!-- profile -->
                    <div class="profile-box d-flex flex-row align-items-center" [matMenuTriggerFor]="profileMenu">
                        <div class="profile">
                            <img src="assets/images/user.png" alt="profile" srcset="" />
                        </div>
                        <div class="user-info d-flex flex-column">
                            <div class="user-name">{{ username }}</div>
                            <div class="user-role">{{ role }}</div>
                        </div>
                        <mat-icon class="btn-arrow-down">keyboard_arrow_down</mat-icon>
                        <mat-menu #profileMenu="matMenu" class="custom-menu">
                            <button mat-menu-item (click)="userChangePwdHandler()">
                                <mat-icon>lock_reset</mat-icon>
                                <span>{{
                                    "actions.change_pwd" | translate
                                    }}</span>
                            </button>
                            <button mat-menu-item (click)="logoutHandler()" class="error">
                                <mat-icon>logout</mat-icon>
                                <span>{{ "actions.logout" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </mat-toolbar>
            <div class="app-container">
                <router-outlet />
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>